import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartOptions,
  ChartData,
} from "chart.js";
import "../styling/DataFormat.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

interface BarChartProps {
  areaData: number[];
  areaLabels: string[];
}

const BarChart: React.FC<BarChartProps> = ({ areaData, areaLabels }) => {
  const backgroundColors = [
    "rgba(255, 99, 132, 0.5)",
    "rgba(75, 192, 192, 0.5)",
    "rgba(255, 205, 86, 0.5)",
    "rgba(153, 102, 255, 0.5)",
    "rgba(255, 159, 64, 0.5)",
    "rgba(201, 203, 207, 0.5)",
    "rgba(54, 162, 235, 0.5)",
    "rgba(255, 99, 71, 0.5)",
    "rgba(144, 238, 144, 0.5)",
    "rgba(173, 216, 230, 0.5)",
    "rgba(238, 130, 238, 0.5)",
  ];

  // Ensure the number of colors matches the number of labels
  const adjustedColors = areaLabels.map(
    (_, index) => backgroundColors[index % backgroundColors.length]
  );

  const barData = {
    labels: areaLabels,
    datasets: [
      {
        data: areaData,
        backgroundColor: adjustedColors,
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: { 
        display: true,
        text: "Product Area Analysis",
        font: {
          size: 25,
        },
        color: "white",
      },
      tooltip: {
        bodyFont: {
          size: 18, // Increase the font size of the tooltip body text
        },
        titleFont: {
          size: 20, // Increase the font size of the tooltip title text
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 16, // Increase the font size of the labels on the x-axis (bar labels)
          },
          color: "white", // Optional: Change the color of the x-axis labels
        },
      },
      y: {
        ticks: {
          font: {
            size: 16, // Increase the font size of the numbers on the y-axis
          },
          color: "white", // Optional: Change the color of the y-axis labels
        },
        suggestedMax: Math.max(...areaData) + 1, // Optional: Increase the maximum value on the y-axis
      },
    },
  };

  return <Bar data={barData} options={barOptions} />;
};

export default BarChart;
