import React, { useEffect, useState } from "react";
import { LeftTextBoxLink } from "./TextBox.tsx";
import axios from "axios";
import { root_url } from "../App";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  product: string;
  sentiment: string;
  performance: string;
}

interface Review {
  review: string;
  name: string;
  date: string;
  link: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  product,
  sentiment,
  performance,
}) => {
  const [page, setPage] = useState<number>(1);
  const [reviews, setReviews] = useState<Review[]>([]);

  const moveLeft = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const moveRight = () => {
    setPage((prev) => prev + 1);
  };

  // Trigger API call when modal opens or relevant props change
  useEffect(() => {
    if (isOpen) {
      const controller = new AbortController();

      axios
        .get(`${root_url}/data/get_data_viewer`, {
          params: {
            product_id: product,
            sentiment: sentiment,
            performance: performance,
            page: page,
          },
          signal: controller.signal,
        })
        .then((res) => {
          const receivedData = res.data;
          setReviews(
            receivedData.map((item: [string, string, string, string]) => {
              return {
                review: item[0],
                date: item[1], // Changed to 'date' to match the interface
                name: item[2],
                link: item[3],
              };
            })
          );
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log("Request canceled:", err.message);
          } else {
            console.error("Error fetching reviews data:", err);
          }
        });

      // Clean up
      return () => {
        controller.abort();
      };
    }
  }, [isOpen, product, sentiment, performance, page]); // Include `isOpen` and `page` as dependencies

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose(); // Close modal on "Escape" key press
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "unset"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "unset"; // Clean up
    };
  }, [isOpen]);

  if (!isOpen) {
    return null; // If modal is not open, don't render it (unmounts the component)
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Reviews Data Viewer</h2>
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {children}
          {reviews.length > 0 ? (
            reviews.map((review, idx) => (
              <LeftTextBoxLink
                key={idx}
                value={review.review}
                name={review.name}
                date={review.date}
                link={review.link}
                idx={idx}
              />
            ))
          ) : (
            <p>No reviews available</p>
          )}
        </div>
        <div className="button-container">
          <div className="d-flex">
            <button className="button-styles" onClick={moveLeft}>
              <i className="bi bi-arrow-left"></i>
            </button>
            <button className="button-styles" onClick={moveRight}>
              <i className="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
