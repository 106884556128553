import React from "react";
import { NavLink } from "react-router-dom";
import "../styling/Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <NavLink className="link" to="/">
        <p>Home</p>
      </NavLink>
      <NavLink className="link" to="/dashboard">
        <p>Data Overview</p>
      </NavLink>
      <NavLink className="link" to="/insight">
        <p>Insights</p>
      </NavLink>
      <NavLink className="link" to="/competitors">
        <p>Competitors</p>
      </NavLink>
      <NavLink className="link" to="/paulle">
        <p>Paulle</p>
      </NavLink>
    </div>
  );
};

export default Sidebar;
