import React, { useState, useEffect } from "react";
import { root_url } from "../App";
import axios from "axios";

interface AISummaryData {
  category: string;
  product_name: string;
  sentiment: string;
}

const AISummary: React.FC<{ reportDetails: AISummaryData }> = ({
  reportDetails,
}) => {
  const [aiSummaryData, setAISummaryData] = useState<string>(
    "No Report Generated"
  );

  useEffect(() => {
    console.log(reportDetails);
  }, [reportDetails]);

  const handleGenerateAISummary = () => {
    setAISummaryData("Generating");

    axios
      .get(`${root_url}/openAI/get_ai_summary`, {
        params: {
          product_id: reportDetails.product_name,
          category: reportDetails.category,
          sentiment: reportDetails.sentiment,
        },
      })
      .then((res) => {
        const receivedData = res.data;
        // console.log(receivedData);
        setAISummaryData(receivedData);
      })
      .catch((err) => {
        console.error("Error fetching AI Summary:", err);
        setAISummaryData("No Report Generated");
      });
  };

  return (
    <div className="ai-summary-container generate-report">
      <h2 style={{ textDecoration: 'underline' }}>AI Summary for {reportDetails.product_name}</h2>
      {aiSummaryData === "No Report Generated" ? (
        <div className="no-report">
          <p>No AI Summary has been generated for this product.</p>
          <p>Category: {reportDetails.category}</p>
          <p>Sentiment: {reportDetails.sentiment}</p>
          <p>Product: {reportDetails.product_name}</p>
          <button className="generate-btn" onClick={handleGenerateAISummary}>
            Generate AI Summary
          </button>
        </div>
      ) : aiSummaryData === "Generating" ? (
        <div className="loading-container">
          <p className="loading-text">Generating AI Summary...</p>
          <div className="wave">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <div>
          <p className="summary-report">{aiSummaryData}</p>
          <button className="generate-btn" onClick={handleGenerateAISummary}>
            Generate AI Summary
          </button>
        </div>
      )}
    </div>
  );
};

export default AISummary;
