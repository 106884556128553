import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { root_url } from "../App";
import "../styling/Paulle.css";

const Paulle = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const message = {
      message: input,
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      { message: input, class: "user" },
    ]);
    setIsLoading(true); // Set loading state to true

    axios
      .post(`${root_url}/chat/send`, message)
      .then((response) => {
        const message_response = response.data.response.output;
        console.log(response.data.response, message_response);
        if (Array.isArray(message_response)) {
          const reviews = response.data.reviews;
          setMessages((prevMessages) => [
            ...prevMessages,
            ...reviews.map((review) => ({ message: review, class: "review" })),
          ]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { message: message_response, class: "normal" },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after response
      });

    setInput("");
  };

  return (
    <div id="dashboard">
      <h1>Dashboard</h1>
      <p className="header-desc">
        The table is organized in the following manner. To increase accuracy,
        please utilize the keywords shown below in your prompts.
      </p>
      <table border="1" className="header-table">
        <tr>
          <th>Brand</th>
          <th>Date</th>
          <th>Product</th>
          <th>Review</th>
          <th>Sentiment</th>
          <th>Topic</th>
        </tr>
      </table>
      <p className="header-subdesc">
        I.e. "what is the main complaint for the{" "}
        <span style={{ color: "red" }}>product</span> Voyager free 20?" or
        "show me some positive <span style={{ color: "red" }}>reviews</span>{" "}
        for the <span style={{ color: "red" }}>product</span> Voyager
        Surround 80 with an audio 
        <span style={{ color: "red" }}> topic</span> area"
      </p>
      <div className="chat-frame">
        {messages.map((message, index) => (
          <div key={index} className={`holder ${message.class}`}>
            <p>{message.message}</p>
          </div>
        ))}
        <div ref={bottomRef} />
      </div>

      <form className="textbox-cont" onSubmit={handleSubmit}>
        <label className="cb-text">
          {isLoading ? (
            <div className="loading-dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          ) : (
            <input
              type="text"
              placeholder="Ask Paulle anything..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="chatbot"
              disabled={isLoading}
            />
          )}
        </label>
        <button
          className="chatbot cb-button"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default Paulle;
