import { React } from 'react';
import "../styling/Components.css";

interface NumberProps {
    number: number;
}

const Number: React.FC<NumberProps> = ({ number }) => {
    return (
        <div id="number-container">
            <h2 id="display-number">{number}</h2>
        </div>
    );
}

export default Number;