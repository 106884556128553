import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingDots from "../components/LoadingDots.tsx";
import Comparison from "../competitors/Comparison.tsx";
import { root_url } from "../App";

import "../styling/Competitors.css";

const name_to_logo = {
  "Bose Quiet Comfort": "Bose-logo.png",
  "Jabra Evolve": "jogo-jabra.png",
  "Logitech Zone": "logitech-logo.png",
};

const Competitors = () => {
  const [selectPolyProduct, setSelectPolyProduct] =
    useState<string>("None Selected");
  const [selectPolyProductList, setSelectPolyProductList] = useState<string[]>(
    []
  );
  const [selectCompetitorProduct, setSelectCompetitorProduct] =
    useState<string>("None Selected");
  const [selectCompetitorProductList, setSelectCompetitorProductList] =
    useState<string[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    axios
      .get(`${root_url}/get_product_line_data_all`, {
        signal: controller.signal,
      })
      .then((res) => {
        const receivedData = res.data;

        console.log(receivedData.poly);

        let poly_lines = receivedData.poly;
        const competitor_lines = receivedData.competitor;

        poly_lines = poly_lines.map(
          (item: [number, string]) => item[1]
        );

        const transformedData = poly_lines.map((item: string) => item);

        setSelectCompetitorProductList(["None Selected", ...competitor_lines]);
        setSelectPolyProductList(["None Selected", ...transformedData]);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          setSelectPolyProduct("Error");
          console.error("Error fetching product line data:", err);
        }
      });

    return () => {
      controller.abort();
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleSelectChangePoly = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;

    setSelectPolyProduct(selectedValue);
  };

  const handleSelectChangeCompetitor = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;

    setSelectCompetitorProduct(selectedValue);
  };

  return (
    <div id="dashboard">
      <h1>Competitor Analysis</h1>
      <div className="competitor-partition">
        <div className="poly-side">
          <div className="logo-container">
            <img src="/images/poly.png" alt="Poly Logo" className="logo" />
          </div>
          <select
            className="product-selection"
            value={selectPolyProduct}
            onChange={handleSelectChangePoly}
          >
            {selectPolyProductList.map((productLine) => (
              <option key={productLine} value={productLine}>
                {productLine}
              </option>
            ))}
          </select>
          {selectPolyProduct === "None Selected" ? (
            <div className="empty-container">
              <h2>None Selected</h2>
            </div>
          ) : selectPolyProduct === "Loading" ? (
            <LoadingDots />
          ) : selectPolyProduct === "Error" ? (
            <div className="empty-container">
              <h2>Error fetching data</h2>
            </div>
          ) : (
            <h2 className="comparison-title">{selectPolyProduct}</h2>
          )}
        </div>
        <div className="competitor-side">
          <div className="logo-container">
            {selectCompetitorProduct !== "None Selected" ? (
              <img
                src={`/images/${name_to_logo[selectCompetitorProduct]}`}
                alt={`/images/${name_to_logo[selectCompetitorProduct]}`}
                className="logo"
              />
            ) : null}
          </div>
          <select
            className="product-selection"
            value={selectCompetitorProduct}
            onChange={handleSelectChangeCompetitor}
          >
            {selectCompetitorProductList.map((productLine) => (
              <option key={productLine} value={productLine}>
                {productLine}
              </option>
            ))}
          </select>
          {selectCompetitorProduct === "None Selected" ? (
            <div className="empty-container">
              <h2>None Selected</h2>
            </div>
          ) : selectCompetitorProduct === "Loading" ? (
            <LoadingDots />
          ) : selectCompetitorProduct === "Error" ? (
            <div className="empty-container">
              <h2>Error fetching data</h2>
            </div>
          ) : (
            <h2 className="comparison-title">{selectCompetitorProduct}</h2>
          )}
        </div>
      </div>
      {selectPolyProduct !== "None Selected" &&
      selectCompetitorProduct !== "None Selected" ? (
        <Comparison comp1={selectPolyProduct} comp2={selectCompetitorProduct} />
      ) : null}
    </div>
  );
};

export default Competitors;
