import React, { useState } from "react";

const source_ordering = [
  "amazon",
  "reddit",
  "quora",
  "bazaarvoice",
  "instagram",
  "facebook",
  "lithium",
  "linkedin",
];

const icon_ordering = [
  <img
    key="1"
    className="source-icon"
    src={"/images/amazon.svg"}
    alt="amazon"
  />,
  <img
    key="2"
    className="source-icon"
    src={"/images/reddit.svg"}
    alt="reddit"
  />,
  <img key="3" className="source-icon" src={"/images/quora.svg"} alt="quora" />,
  <img
    key="4"
    className="source-icon"
    src={"/images/megaphone-fill.svg"}
    alt="bazaarvoice"
  />,
  <img
    key="5"
    className="source-icon"
    src={"/images/instagram.svg"}
    alt="instagram"
  />,
  <img
    key="6"
    className="source-icon"
    src={"/images/facebook.svg"}
    alt="facebook"
  />,
  <img
    key="7"
    className="source-icon"
    src={"/images/battery-full.svg"}
    alt="lithium"
  />,
  <img
    key="8"
    className="source-icon"
    src={"/images/linkedin.svg"}
    alt="linkedin"
  />,
];

const colors = [
  "#D6E4FF",
  "#FFD6E0",
  "#FFF0D6",
  "#D6FFEA",
  "#E0D6FF",
  "#FFEDD6",
  "#D6FFF7",
  "#F7D6FF",
];

const StackedBar = ({ data }) => {
  const [tooltip, setTooltip] = useState({
    visible: false,
    value: "",
  });
  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = (index, event, value) => {
    const newX = event.clientX + 10;
    const newY = event.clientY - 30; // Adjust as needed to position the tooltip correctly
    setTooltip({
      visible: true,
      value,
    });
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
    setHoverIndex(null);
  };

  return (
    <div className="sales-bar-container">
      <h3>Data Origins</h3>
      <div className="sales-bar">
        {data.map(
          (value: number, index: number) =>
            value > 0 && (
              <div
                key={index}
                className="stacked-segment"
                style={{
                  flex: `${value} 0 0`,
                  backgroundColor: colors[index],
                  cursor: "pointer",
                  opacity: hoverIndex === index ? 0.7 : 1
                }}
                onMouseEnter={(event) =>
                  handleMouseEnter(index, event, `${source_ordering[index]}: ${value}`)
                }
                onMouseLeave={handleMouseLeave}
              ></div>
            )
        )}
      </div>
      <div className="icons">
        {data.map((value, index) => (
          value > 0 && React.cloneElement(icon_ordering[index], {
            style: { opacity: hoverIndex === index ? 0.5 : 1 }
          })
        ))}
      </div>
      {tooltip.visible && <div>{tooltip.value}</div>}
    </div>
  );
};

export default StackedBar;
