import React, { useState, useEffect } from "react";
import axios from "axios";
import DataSummary from "../components/DataSummary.tsx";
import LoadingDots from "../components/LoadingDots.tsx";
import { root_url } from "../App";
import "../styling/Dashboard.css";

interface ProductLine {
  productLine: string;
  productLineId: number;
}

export type ProductItem = {
  sentiments: number[];
  product_model: string;
  performance: number[];
  undetermined: number;
  sources: number[];
};

const Dashboard: React.FC = () => {
  const [productLines, setProductLines] = useState<ProductLine[]>([]);
  const [products, setProducts] = useState<ProductItem[]>([]);
  const [selectedProductLine, setSelectedProductLine] =
    useState<string>("None Selected");
  const [searchedItem, setSearchedItem] = useState<string>("");

  useEffect(() => {
    const controller = new AbortController();

    axios
      .get(`${root_url}/data/get_product_line_data`, { signal: controller.signal })
      .then((res) => {
        const receivedData = res.data;

        const transformedData = receivedData.map((item: [number, string]) => ({
          productLineId: item[0],
          productLine: item[1],
        }));

        setProductLines([
          { productLine: "None Selected", productLineId: -1 },
          ...transformedData,
        ]);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          setSelectedProductLine("Error");
          console.error("Error fetching product line data:", err);
        }
      });

    return () => {
      controller.abort(); // Cancel the request if the component unmounts
    };
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (selectedValue === "None Selected") {
      setSelectedProductLine("None Selected");
      setProducts([]);
      return;
    }

    setSelectedProductLine("Loading");

    const productLineObject = productLines.find(
      (productLine) => productLine.productLine === selectedValue
    );

    if (!productLineObject) {
      setSelectedProductLine("Error");
      console.error("Product line not found:", selectedValue);
      return;
    }

    const request = {
      product_id: productLineObject.productLineId,
    };

    const controller = new AbortController();

    axios
      .post(`${root_url}/data/get_product_high_level`, request, { signal: controller.signal })
      .then((res) => {
        const receivedData = res.data;

        const transformedData = receivedData.map((item: ProductItem) => ({
          product_model: item.product_model,
          sentiments: item.sentiments,
          performance: item.performance,
          undetermined: item.undetermined,
          sources: item.sources,
        }));

        setSelectedProductLine(selectedValue);
        setProducts(transformedData);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          setSelectedProductLine("Error");
          console.error("Error fetching product data:", err);
        }
      });

    return () => {
      controller.abort(); // Cancel the request if the component unmounts
    };
  };

  return (
    <div id="dashboard">
      <h1>Data Overview</h1>
      <section className="selection-container">
        <h3>Select a Product Line: </h3>
        <select
          className="product-selection"
          value={selectedProductLine}
          onChange={handleSelectChange}
        >
          {productLines.map((productLine) => (
            <option key={productLine.productLineId}>
              {productLine.productLine}
            </option>
          ))}
        </select>
      </section>
      {selectedProductLine === "None Selected" ? (
        <div className="empty-container">
          <h2>None Selected</h2>
        </div>
      ) : selectedProductLine === "Loading" ? (
        <div className="empty-container">
          <LoadingDots />
        </div>
      ) : selectedProductLine === "Error" ? (
        <div className="empty-container">
          <h2>Error fetching data</h2>
        </div>
      ) : (
        <>
          <input
            type="text"
            className="product-selection"
            placeholder="Search for a product"
            onChange={(e) => setSearchedItem(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          {products &&
            (searchedItem === ""
              ? products
              : products.filter((product) =>
                  product.product_model
                    .toLowerCase()
                    .includes(searchedItem.toLowerCase())
                )
            ).map((product, index) => (
              <DataSummary key={index} productItem={product} />
            ))}
        </>
      )}
    </div>
  );
};

export default Dashboard;
