import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingDots from "../components/LoadingDots.tsx";
import ProductLineInsight from "../insights/ProductLineInsight.tsx";
import { root_url } from "../App";
import "../styling/Insight.css";

interface ProductLine {
  productLine: string;
  productLineId: number;
}

const Insight = () => {
  const [productLines, setProductLines] = useState<ProductLine[]>([]);
  const [selectedProductLine, setSelectedProductLine] =
    useState<string>("None Selected");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(`${root_url}/data/get_product_line_data`)
      .then((res) => {
        const receivedData = res.data;

        const transformedData = receivedData.map((item: [number, string]) => ({
          productLineId: item[0],
          productLine: item[1],
        }));

        setProductLines([
          { productLine: "None Selected", productLineId: -1 },
          ...transformedData,
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setSelectedProductLine("Error");
        setLoading(false);
        console.error("Error fetching product line data:", err);
      });
  }, []);

  const handleProductLineChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedProductLine(event.target.value);
  };

  return (
    <div id="dashboard">
      <h1>Insights</h1>
      <div className="product_line_box">
        {loading ? (
          <LoadingDots />
        ) : (
          <section className="selection-container">
            <h3>Select a Product Line: </h3>
            <select
              className="product-selection"
              value={selectedProductLine}
              onChange={handleProductLineChange}
            >
              {productLines.map((line) => (
                <option key={line.productLineId} value={line.productLine}>
                  {line.productLine}
                </option>
              ))}
            </select>
          </section>
        )}
      </div>
      {selectedProductLine === "None Selected" ? (
        null
      ) : selectedProductLine === "Loading" ? (
        <div className="empty-container">
          <LoadingDots />
        </div>
      ) : selectedProductLine === "Error" ? (
        <div className="empty-container">
          <h2>Error fetching data</h2>
        </div>
      ) : (
        <div className="product-line-container">
          <p className="insight-title">{selectedProductLine}</p>
          <ProductLineInsight productLine={selectedProductLine} />
        </div>
      )}
    </div>
  );
};

export default Insight;
