import React, { useState, useEffect } from 'react';

const LoadingDots: React.FC = () => {
  const [dots, setDots] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
    }, 400);

    return () => clearInterval(interval); 
  }, []);

  return <h2>Fetching Data{dots}</h2>;
};

export default LoadingDots;
