import React, { useState } from "react";
import { ProductItem } from "../pages/Dashboard";
import BarChart from "./BarChart.tsx";
import PieChart from "./PieChart.tsx";
import Number from "./Number.tsx";
import StackedBar from "./StackedBar.tsx";
import Modal from "./Modal.tsx";

interface DataSummaryProps {
  productItem: ProductItem;
}

const DataSummary: React.FC<DataSummaryProps> = ({ productItem }) => {
  const [selectedSentiment, setSelectedSentiment] = useState<string>("None");
  const [selectedPerformance, setSelectedPerformance] =
    useState<string>("None");

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Function to toggle the modal
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const barLabels = [
    "Customer Service",
    "Audio",
    "Visual",
    "Bluetooth",
    "Microphone",
    "Battery Life",
    "Internet",
    "Performance/Speed",
    "Value/Price",
    "Ease of Use",
    "Comfortability/Fit"
];
  const pieLabels = ["Positive", "Neutral", "Negative"];

  return (
    <div className="miniboard-container">
      <p className="data-title">{productItem.product_model}</p>
      <div className="row data-summary space-between">
        <div style={{ flex: "1", display: "flex", justifyContent: "center" }}>
          <img
            className="display-image"
            src={`/product_images/${productItem.product_model
              .replace("/", "-")
              .split(" ")
              .join("_")}.jpg`}
            alt="product"
            style={{ height: "220px", width: "auto" }}
          />
        </div>
        <StackedBar data={productItem.sources} />
      </div>
      <div className="row data-summary">
        <div className="col-lg-5 col-md-12 col-sm-12 pie-chart">
          <PieChart
            categories={pieLabels}
            productValues={productItem.sentiments}
          />
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 bar-chart">
          <BarChart areaData={productItem.performance} areaLabels={barLabels} />
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12 number-container">
          <Number number={productItem.undetermined} />
          <p className="number-des">Undetermined Areas</p>
        </div>
      </div>
      <div className="row center">
        <select
          className="sentiment select"
          onChange={(e) => setSelectedSentiment(e.target.value)}
          value={selectedSentiment}
        >
          <option value="None">None</option>
          {pieLabels.map((label) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
        </select>
        <select
          className="performance select"
          onChange={(e) => setSelectedPerformance(e.target.value)}
          value={selectedPerformance}
        >
          <option value="None">None</option>
          {barLabels.map((label) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
        </select>
        {/* Toggle modal on button click */}
        <button className="modal-button select" onClick={toggleModal}>
          View Details
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        product={productItem.product_model}
        sentiment={selectedSentiment}
        performance={selectedPerformance}
      >
        <div>
          <h2>Inside Look for {productItem.product_model}</h2>
          {/* You can add more detailed content here */}
        </div>
      </Modal>
    </div>
  );
};

export default DataSummary;
