import { React } from "react";
import "../styling/TitleTop.css";

const TitleTop = () => {
  return (
    <div className="heading">
      <img src="/images/hp.png" alt="HP logo" className="hplogo" />
      <img src="/images/poly.png" alt="Poly logo" className="polylogo" />
      <h2 className="heading-h2">Insights</h2>
    </div>
  );
};

export default TitleTop;