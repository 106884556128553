import React, { useState } from 'react';

const InfoIcon = ({ text }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="info-icon-container"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className="info-icon">ℹ️</span> 
      {hover && <div className="tooltip">{text}</div>}
    </div>
  );
};

export default InfoIcon;
