import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartOptions,
} from 'chart.js';
import "../styling/DataFormat.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

interface DataSummaryProps {
    categories: string[];
    productValues: number[];
}

const PieChart: React.FC<DataSummaryProps> = ({ categories, productValues }) => {

  const pieOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          font: {
            size: 17,
          },
          padding: 20,
          color: 'white',
        }
      },
      tooltip: {
        bodyFont: {
          size: 18, // Increase the font size of the tooltip body text
        },
        titleFont: {
          size: 20, // Increase the font size of the tooltip title text
        },
      },
      title: {
        display: true,
        text: 'Sentiment Analysis',
        font: {
          size: 25,
        },
        color: 'white',
      },
    },
  };

  const chartData = {
    labels: categories,
    datasets: [
      {
        data: productValues,
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
      <Pie data={chartData} options={pieOptions} />
  );
};

export default PieChart;
