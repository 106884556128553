import { React, useEffect, useState } from "react";
import "../styling/Home.css";
import TitleTop from "../components/TitleTop.jsx";
import axios from "axios";
import { root_url } from "../App";
import { LeftTextBox, RightTextBox } from "../components/TextBox.tsx";
import LoadingDots from "../components/LoadingDots.tsx";

const Home = () => {
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const dataPackage = { page_num: page, page_size: 5 };

    axios
      .get(root_url + "/data/get_five_recent_messages", {
        params: dataPackage,
      })
      .then((response) => {
        if (response.data.error) {
          console.log("Error: " + response.data.error);
        } else {
          setMessages(response.data);
          // console.log(response.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page]); // Depend on 'page' so the effect runs whenever 'page' changes

  const updateMessages = (page_num) => {
    const dataPackage = { page_num: page_num, page_size: 5 };

    axios
      .get(root_url + "/data/get_five_recent_messages", {
        params: dataPackage,
      })
      .then((response) => {
        if (response.data.error) {
          console.log("Error: " + response.data.error);
        } else {
          setMessages(response.data);
          // console.log(response.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveLeft = () => {
    if (page > 1) {
      setPage(page - 1); // Update the page state
    }
  };

  const moveRight = () => {
    setPage(page + 1); // Update the page state
  };

  return (
    <section className="Home">
      <TitleTop />
      <p style={{ marginTop: "20px" }} className="subtitle">
        Welcome to budget Sprinklr!
      </p>
      <p className="subtitle">Here are what people are saying recently...</p>
      <div className="messages">
        {messages.length === 0 ? (
          <LoadingDots />
        ) : (
          messages.map((message, index) =>
            index % 2 === 0 ? (
              <LeftTextBox
                key={index}
                idx={index}
                value={message[1]}
                name={message[7]}
                date={message[2]}
              />
            ) : (
              <RightTextBox
                key={index}
                idx={index}
                value={message[1]}
                name={message[7]}
                date={message[2]}
              />
            )
          )
        )}
        <div className="button-container">
          <div className="d-flex">
            <button className="button-styles" onClick={moveLeft}>
              <i className="bi bi-arrow-left"></i>
            </button>
            <button className="button-styles" onClick={moveRight}>
              <i className="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
