import React, { useState, useEffect } from "react";
import AISummary from "./AISummary.tsx";
import { root_url } from "../App";
import InfoIcon from "../components/InfoIcon.tsx";
import axios from "axios";

interface ModelSpecificData {
  category: string;
  earliest_date: string;
  total_messages: number;
  topFeatures: string[];
  bottomFeatures: string[];
}

const DataInsight: React.FC<{ productModel: string }> = ({ productModel }) => {
  const [modelSpecificData, setModelSpecificData] =
    useState<ModelSpecificData>();
  const [topFeatures, setTopFeatures] = useState<string[]>([]);
  const [bottomFeatures, setBottomFeatures] = useState<string[]>([]);
  const [positiveGenerate, setPositiveGenerate] = useState<string>("generate");
  const [negativeGenerate, setNegativeGenerate] = useState<string>("generate");
  const [positiveSummary, setPositiveSummary] = useState<string>("");
  const [negativeSummary, setNegativeSummary] = useState<string>("");

  useEffect(() => {
    axios
      .get(`${root_url}/insights/get_product_level_insights`, {
        params: { product_id: productModel },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const receivedData = res.data;
        const date = new Date(receivedData.dates[0]);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

        setModelSpecificData({
          topFeatures: receivedData.pos_areas,
          bottomFeatures: receivedData.neg_areas,
          category: receivedData.category[0],
          earliest_date: formattedDate,
          total_messages: receivedData.total_messages,
        });

        // setTopFeatures(receivedData.pos_areas.map((feature) => feature[0]));
        // setBottomFeatures(receivedData.neg_areas.map((feature) => feature[0]));
        setTopFeatures([receivedData.pos_areas[0][0]]);
        setBottomFeatures([receivedData.neg_areas[0][0]]);
      })
      .catch((err) => {
        console.error("Error fetching product-level insights:", err);
      });
  }, [productModel]);

  const getPositiveSummary = () => {
    axios
      .get(`${root_url}/insights/get_positive_messages`, {
        params: { product_id: productModel },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const receivedData = res.data;
        setPositiveSummary(receivedData.positive_messages);
        setPositiveGenerate("generated");
      })
      .catch((err) => {
        console.error("Error fetching product-level insights:", err);
      });
  };

  const getNegativeSummary = () => {
    axios
      .get(`${root_url}/insights/get_negative_summarize_areas`, {
        params: { product_id: productModel },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const receivedData = res.data;
        setNegativeSummary(receivedData.negative_messages);
        setNegativeGenerate("generated");
      })
      .catch((err) => {
        console.error("Error fetching product-level insights:", err);
      });
  };

  return (
    <div className="data-insight-container">
      <div className="row space-around" style={{ alignItems: "center" }}>
        <img
          className="display-image"
          src={`/product_images/${productModel
            .replace("/", "-")
            .split(" ")
            .join("_")}.jpg`}
          alt="product"
          style={{ height: "220px", width: "auto" }}
        />
        <section className="image-info-caption">
          <h3>
            <span className="highlight1">Product Model:</span> {productModel}
          </h3>
          <h3>
            <span className="highlight1">Device Category:</span>{" "}
            {modelSpecificData?.category}
          </h3>
          <h3>
            <span className="highlight1">Earliest Captured Review:</span>{" "}
            {modelSpecificData?.earliest_date}
          </h3>
          <h3>
            <span className="highlight1">Total Reviews:</span>{" "}
            {modelSpecificData?.total_messages}
          </h3>
        </section>
      </div>
      <section className="actionable-insights">
        <h3>WHATS WORKING</h3>
        <div className="actionable-partition">
          <div className="actionable-left">
            <p className="performing-title">Best Performing Areas</p>
            <ul>
              {modelSpecificData?.topFeatures.map((feature, index) => (
                <li key={index}>
                  {feature[0]}
                  -
                  <InfoIcon text="Calculated Using the Wilson index" />
                </li>
              ))}
            </ul>
          </div>
          {positiveGenerate === "generate" ? (
            <div className="actionable-right">
              <div className="ai-summary no-report">No Generated Report</div>
              <button
                onClick={() => {
                  getPositiveSummary();
                  setPositiveGenerate("generating");
                }}
              >
                Generate AI Summary
              </button>
            </div>
          ) : positiveGenerate === "generating" ? (
            <div className="actionable-right">
              <div className="ai-summary ai-generating">
                <p>Generating...</p>
              </div>
            </div>
          ) : (
            <div className="actionable-right">
              <div className="ai-summary report">
                <p>{positiveSummary}</p>
              </div>
              <button
                onClick={() => {
                  getPositiveSummary();
                  setPositiveGenerate("generating");
                }}
              >
                Regenerate
              </button>
            </div>
          )}
          {topFeatures?.map((feature, index) => (
            <AISummary
              key={index}
              reportDetails={{
                category: feature,
                product_name: productModel,
                sentiment: "positive",
              }}
            />
          ))}
        </div>
      </section>
      <section className="actionable-insights">
        <h3>AREAS FOR IMPROVEMENT</h3>
        <div className="actionable-partition">
          <div className="actionable-left">
            <p className="performing-title">Worst Performing Areas</p>
            <ul>
              {modelSpecificData?.bottomFeatures.map((feature, index) => (
                <li key={index}>
                  {feature[0]}
                  <InfoIcon text="Calculated Using the Wilson index" />
                </li>
              ))}
            </ul>
          </div>
          {negativeGenerate === "generate" ? (
            <div className="actionable-right">
              <div className="ai-summary no-report">No Generated Report</div>
              <button
                onClick={() => {
                  getNegativeSummary();
                  setNegativeGenerate("generating");
                }}
              >
                Generate AI Summary
              </button>
            </div>
          ) : negativeGenerate === "generating" ? (
            <div className="actionable-right">
              <div className="ai-summary ai-generating">
                <p>Generating...</p>
              </div>
            </div>
          ) : (
            <div className="actionable-right">
              <div className="ai-summary report">
                <p>{negativeSummary}</p>
              </div>
              <button
                onClick={() => {
                  getNegativeSummary();
                  setNegativeGenerate("generating");
                }}
              >
                Regenerate
              </button>
            </div>
          )}
          {bottomFeatures?.map((feature, index) => (
            <AISummary
              key={index}
              reportDetails={{
                category: feature,
                product_name: productModel,
                sentiment: "negative",
              }}
            />
          ))}
        </div>
      </section>
      <section className="actionable-insights">
        <h3>TIME SERIES</h3>
        <p style={{ fontSize: "23px" }}>Coming Soon...</p>
      </section>
    </div>
  );
};

export default DataInsight;
