import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingDots from "../components/LoadingDots.tsx";
import { root_url } from "../App";
import "../styling/Competitors.css";

interface CompetitorsProps {
  comp1: string;
  comp2: string;
}

interface summaryData {
  perf_area: string[];
  prod1: number[];
  prod2: number[];
}

const Competitors: React.FC<CompetitorsProps> = ({ comp1, comp2 }) => {
  const [summary, setSummary] = useState<summaryData | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    const queryPackage = {
      product_id: comp1,
      competitor_id: comp2,
    };

    axios
      .get(`${root_url}/get_product_sentiment_per_performance`, {
        params: queryPackage,
        signal: controller.signal,
      })
      .then((res) => {
        const receivedData = res.data;
        const summary = receivedData.summary;

        setSummary({
          perf_area: summary[0],
          prod1: summary[1],
          prod2: summary[2],
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(
            "Error fetching product sentiment per performance:",
            err
          );
        }
      });

    return () => {
      controller.abort();
    };
  }, [comp1, comp2]);

  return (
    <div style={{ padding: '30px'}}>
      <h2 style = {{ fontSize : '50px'}}>
        {comp1} vs {comp2}
      </h2>
      {summary ? (
        <div style = {{ padding: '30px'}}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Performance Area</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>{comp1}</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>{comp2}</th>
            </tr>
          </thead>
          <tbody>
            {summary.perf_area.map((area, index) => {
              const prod1Value = summary.prod1[index];
              const prod2Value = summary.prod2[index];
      
              return (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{area}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                    {prod1Value > prod2Value ? "⭐" : prod1Value === prod2Value ? "✔️" : ""}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                    {prod2Value > prod1Value ? "⭐" : prod2Value === prod1Value ? "✔️" : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};

export default Competitors;
