import React, { useState, useEffect } from "react";
import DataInsight from "./DataInsight.tsx";
import { root_url } from "../App";
// import LoadingDots from "../components/LoadingDots.tsx";
import axios from "axios";
import "../styling/Insight.css";

interface HighLevelData {
  sentiments: number[];
  repeat_customers: number;
  topProducts: string[];
  bottomProducts: string[];
  allProducts: string[];
}

const ProductLineInsight: React.FC<{ productLine: string }> = ({
  productLine,
}) => {
  const [highLevelData, setHighLevelData] = useState<HighLevelData>();
  const [selectedProduct, setSelectedProduct] =
    useState<string>("None Selected");

  useEffect(() => {
    axios
      .get(`${root_url}/insights/get_product_line_high_level`, {
        params: { product_line: productLine },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const receivedData = res.data;

        // console.log("Received data: ", receivedData);

        setHighLevelData({
          sentiments: receivedData.sentiments,
          repeat_customers: receivedData.repeat_customers,
          topProducts: receivedData.top_products,
          bottomProducts: receivedData.bottom_products,
          allProducts: ["None Selected", ...receivedData.all_products],
        });
      })
      .catch((err) => {
        console.error("Error fetching positive message areas:", err);
      });
  }, [productLine]);

  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(event.target.value);
  };

  return (
    <>
      {highLevelData !== null ? (
        <>
          <section className="insight-header">
            <div className="insight-header-column">
              <p>Mentions</p>
              <h3 className="insight-number" style={{ color: "#4682B4" }}>
                {String(
                  highLevelData?.sentiments?.reduce((a, b) => a + b, 0) || 0
                )}
              </h3>
            </div>
            <div className="insight-header-column">
              <p>Satisfaction</p>
              <h3 className="insight-number" style={{ color: "#6B8E23" }}>
                {String(
                  Array.isArray(highLevelData?.sentiments)
                    ? (
                        (highLevelData.sentiments[0] /
                          highLevelData.sentiments.reduce((a, b) => a + b, 0)) *
                        100
                      ).toFixed(2)
                    : "0.00"
                )}
                %
              </h3>
            </div>
            <div className="insight-header-column">
              <p>Dissatisfaction</p>
              <h3 className="insight-number" style={{ color: "#A0522D" }}>
                {String(
                  Array.isArray(highLevelData?.sentiments)
                    ? (
                        (highLevelData.sentiments[2] /
                          highLevelData.sentiments.reduce((a, b) => a + b, 0)) *
                        100
                      ).toFixed(2)
                    : "0.00"
                )}
                %
              </h3>
            </div>
            <div className="insight-header-column">
              <p>Repeat Reviewers</p>
              <h3 className="insight-number" style={{ color: "#9370DB" }}>
                {String(highLevelData?.repeat_customers || 0)}
              </h3>
            </div>
          </section>
          <div className="product-summary-header">
            <div className="product-performance-header">
              <h3>High Performing Products</h3>
              <ul>
                {highLevelData?.topProducts.map((product) =>
                  product === productLine ? (
                    <li key={product}>{product} - General</li>
                  ) : (
                    <li key={product}>{product}</li>
                  )
                )}
              </ul>
            </div>
            <div className="product-performance-header">
              <h3>Low Performing Products</h3>
              <ul>
                <ul>
                  {highLevelData?.bottomProducts.map((product) =>
                    product === productLine ? (
                      <li key={product}>{product} General</li>
                    ) : (
                      <li key={product}>{product}</li>
                    )
                  )}
                </ul>
              </ul>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "black",
              marginBottom: "30px",
            }}
          ></div>
          {highLevelData ? (
            <>
              Please Select A Model
              <select
                className="product-selection"
                value={selectedProduct}
                onChange={handleProductChange}
                style={{ marginBottom: "30px" }}
              >
                {highLevelData?.allProducts.map((line, index) => (
                  <option key={index} value={line}>
                    {line}
                  </option>
                ))}
              </select>
            </>
          ) : null}
          {selectedProduct !== "None Selected" ? (
            <DataInsight productModel={selectedProduct} />
          ) : null}
        </>
      ) : (
        <>
          <p>Error retrieving information</p>
          <p>Please contact developers</p>
        </>
      )}
    </>
  );
};

export default ProductLineInsight;
