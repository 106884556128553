import React from "react";
import "../styling/SpeechBox.css";

interface TextBoxProps {
  value: string;
  name: string;
  date: string;
  idx: number;
}

interface TextBoxPropsWithLink {
  value: string;
  name: string;
  date: string;
  idx: number;
  link: string;
}

const getColorByIndex = (idx: number) => {
  const colors = ["#6C7D9D", "#D98E4F", "#8FBF9C", "#D17A7A", "#9A8EC0"];
  return colors[idx % colors.length];
};

const getColorByIndexProfile = (idx: number) => {
  const colors = ["#4A5464", "#9A5A2B", "#4F7D5C", "#9A4D4D", "#5C4F7D"];
  return colors[idx % colors.length];
};

export const LeftTextBox: React.FC<TextBoxProps> = ({
  value,
  name,
  date,
  idx = 0,
}) => {
  const color = getColorByIndex(idx);
  const colorProfile = getColorByIndexProfile(idx);
  return (
    <div style={{ paddingTop: "20px" }}>
      <p className="right date">{date.substring(0, 17)}</p>
      <div className="message-holder">
        <div
          className="circular-container"
          style={{ backgroundColor: colorProfile }}
        >
          <i className="bi bi-person"></i>
        </div>
        <div
          className="speech-bubble left-speech-bubble"
          style={
            {
              backgroundColor: color,
              "--arrow-color": color,
            } as React.CSSProperties
          }
        >
          {value}
        </div>
      </div>
      <p className="left profile-name">{name}</p>
    </div>
  );
};

export const LeftTextBoxLink: React.FC<TextBoxPropsWithLink> = ({
  value,
  name,
  date,
  link,
  idx = 0,
}) => {
  const color = getColorByIndex(idx);
  const colorProfile = getColorByIndexProfile(idx);
  return (
    <div className="section-holder-link">
      <p className="right date">{date.substring(0, 17)}</p>
      <div className="message-holder-link">
        <div
          className="circular-container"
          style={{ backgroundColor: colorProfile }}
        >
          <i className="bi bi-person"></i>
        </div>
        <div
          className="speech-bubble left-speech-bubble"
          style={
            {
              backgroundColor: color,
              "--arrow-color": color,
            } as React.CSSProperties
          }
        >
          {value}
          <a className="link" href={link} target="_blank" rel="noreferrer" style={{marginLeft: '10px'}}>
            <i className="bi bi-link-45deg"></i>
          </a>
        </div>
      </div>
      <p className="left profile-name">{name}</p>
    </div>
  );
};

export const RightTextBox: React.FC<TextBoxProps> = ({
  value,
  name,
  date,
  idx = 0,
}) => {
  const color = getColorByIndex(idx);
  const colorProfile = getColorByIndexProfile(idx);
  return (
    <div className="section-holder">
      <p className="right date" style={{ marginRight: "60px" }}>
        {date.substring(0, 17)}
      </p>
      <div className="message-holder">
        <div
          className="speech-bubble right-speech-bubble"
          style={
            {
              backgroundColor: color,
              "--arrow-color": color,
            } as React.CSSProperties
          }
        >
          {value}
        </div>
        <div
          className="circular-container"
          style={{ backgroundColor: colorProfile }}
        >
          <i className="bi bi-person"></i>
        </div>
      </div>
      <p className="right profile-name">{name}</p>
    </div>
  );
};
