import React, { useEffect } from 'react';
import axios from 'axios';
import './styling/App.css';
import Sidebar from './components/Sidebar.jsx';
import Home from './pages/Home.jsx';
import Dashboard from './pages/Dashboard.tsx';
import Paulle from './pages/Paulle.jsx';
import Insight from './pages/Insight.tsx';
import Competitors from './pages/Competitors.tsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root_url = 'http://20.84.119.144';

function App() {
  useEffect(() => {
    const handleUnload = (event) => {
      // Send a request to the backend to close the connection
      axios.post(`${root_url}/close_connection`)
        .catch(err => console.error("Error closing connection:", err));
    };

    // Add event listener for when the user is about to close the page or navigate away
    window.addEventListener("beforeunload", handleUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Sidebar />
          <section className="Main-Panel">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/paulle" element={<Paulle />} />
              <Route path="/insight" element={<Insight />} />
              <Route path="/competitors" element={<Competitors />} />
            </Routes>
          </section>
        </header>
      </div>
    </Router>
  );
}

export { root_url };
export default App;
